import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Our Services | Our Services at Crystal Cascade Car Wash</title>
        <meta
          name={"description"}
          content={"Immaculate Attention to Every Detail\n"}
        />
        <meta
          property={"og:title"}
          content={"Our Services | Our Services at Crystal Cascade Car Wash"}
        />
        <meta
          property={"og:description"}
          content={"Immaculate Attention to Every Detail"}
        />
        <meta
          property={"og:image"}
          content={"https://bovirexpro.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://bovirexpro.com/img/car-wash.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://bovirexpro.com/img/car-wash.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://bovirexpro.com/img/car-wash.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://bovirexpro.com/img/car-wash.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://bovirexpro.com/img/car-wash.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://bovirexpro.com/img/car-wash.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Schedule-8"
      >
        <Override
          slot="SectionContent"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="48px 30px"
          lg-grid-template-columns="1fr"
          lg-grid-gap="30px"
          md-grid-gap="32px"
        />
        <Box
          flex-direction="column"
          md-width="100%"
          md-align-items="center"
          md-justify-content="center"
          md-text-align="center"
          align-items="center"
          display="flex"
          grid-column="1 / span 3"
          lg-grid-column="auto / auto"
        >
          <Text
            margin="0px 0px 16px 0px"
            font="--headline1"
            color="--dark"
            md-text-align="center"
            sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            text-align="center"
          >
            Our Services
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--lead"
            color="--darkL2"
            md-text-align="center"
            text-align="center"
            max-width="800px"
            sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
          >
            At Crystal Cascade Car Wash, we offer a variety of services designed
            to meet the needs of every customer and every vehicle. From basic
            washes to complete detailing, our goal is to provide you with an
            exceptional experience that leaves your vehicle sparkling and your
            expectations surpassed.
          </Text>
        <Text
          margin="50px 0px 14px 0px"
          color="--darkL2"
          font="--headline2"
          lg-text-align="left"
        >
          Comprehensive Car Care
        </Text>
        </Box>

        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Self-Service Bays
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Complete Control: Equipped with user-friendly interfaces and
            top-tier cleaning tools.
            <br />
            <br />
            High-Pressure Washes: Remove dirt and grime effortlessly.
            <br />
            <br />
            Foam Brushes: Soft, scratch-free cleaning for delicate surfaces.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Touchless Automatic Wash
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Gentle Yet Effective: Perfect for those who want a quick, thorough
            clean without any contact.
            <br />
            <br />
            Sensor-Guided Jets: Adapt to your car’s shape and size for optimal
            cleaning.
            <br />
            <br />
            Eco-Friendly Detergents: Powerful cleaning that’s safe for your car
            and the environment.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Detailing Services
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Detail-Oriented Perfection: For a showroom finish.
            <br />
            <br />
            Interior Detailing: Deep clean and condition for upholstery,
            carpets, and more.
            <br />
            <br />
            Exterior Detailing: Waxing and polishing to protect and enhance your
            car’s shine.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Undercarriage Cleaning
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Thorough and Protective: Extend the life of your vehicle.
            <br />
            <br />
            Rust Inhibitor: Protects against corrosion and wear.
            <br />
            <br />
            High-Pressure Rinse: Clears away salt, dirt, and debris.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Wheel Cleaning
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Sparkling Rims and Tires: Because every detail counts.
            <br />
            <br />
            Wheel Shine: Restores and protects your wheels’ luster.
            <br />
            <br />
            Tire Dressing: Conditions tires, leaving them rich and black.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Seasonal Care Packages
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Season-Specific Solutions: Tailored to protect your vehicle no
            matter the weather.
            <br />
            <br />
            Winter Protection: Specialized treatments to combat road salt and
            freezing temps.
            <br />
            <br />
            Summer Shine: UV-protectant waxes and window treatments.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://bovirexpro.com/img/4.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Visit Us for More Details
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Our full range of services is designed to cater to your every need,
            ensuring that your car receives the care it deserves. For more
            detailed information about each of our services, please visit
            Crystal Cascade Car Wash or contact us directly. Our friendly team
            is ready to assist you and provide personalized advice based on your
            car’s specific requirements.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
